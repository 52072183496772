/* eslint-disable */

import urlJoin from "url-join";
import { appEnv, isDevelopment } from "./env";
import { getPageDescriptionListName, getPowerBiReportGraphLink, getPageDescriptionGraphLink } from "@/common/spListNames";
import { Web } from "@pnp/sp/webs";
import { PageDescription } from "@/types/PageDescriptionListItem";
import { EmbeddedReport } from "@/types/EmbedReportListItem";
import "@pnp/sp/webs";
import "@pnp/sp/lists";
import "@pnp/sp/items";
import { useAuthenticationStore } from "@/stores/authentication";
import axios from "axios";

// We dont need server endpoints here for now
// const u3tServerEndpoints: Record<typeof process.env.VUE_APP_ENV, string> = {
//   development: "",
//   stage: "",
//   production: "",
// };

// /**
//  * Represents Azure KPI provider server domain
//  */
// export function getU3tServerUrl(): string {
//   return u3tServerEndpoints[appEnv];
// }

/**
 * Represents server domain
 */
export function getSPServerUrl(explicitOrigin = false): string {
  if (explicitOrigin && isDevelopment) {
    return window._spPageContextInfo.portalUrl;
  }

  return "https://abb.sharepoint.com/";
}

/**
 * Represents default sub-site of NO-CompetenceLibrary resolved as a relative path
 */
export function getSPCompetenceLibraryRelativeUrl() {
  return "/sites/NO-CompetenceLibrary";
}

/**
 * Represents default sub-site of NO-CompetenceLibrary/OGC-KPI
 */
export function getSPOgcKpiRelativeUrl() {
  return urlJoin("/sites/NO-CompetenceLibrary", "PA-OGC-KPI");
}

/**
 * Represents default sub-site of NO-CompetenceLibrary
 */
export function getSPCompetenceLibraryUrl(explicitOrigin = false) {
  if (!explicitOrigin) return "https://abb.sharepoint.com/sites/NO-CompetenceLibrary";
  return urlJoin(
    getSPServerUrl(explicitOrigin),
    getSPCompetenceLibraryRelativeUrl()
  );
}

export function getPageDescriptionSiteUrl(): string {
  const base = getSPServerUrl();
  const sites = [...getNoCompetenceSubSites(), "PA-OGC-KPI"];
  return urlJoin(base, ...sites);
}
/**
 * Returns list of sub-sites
 */
function getNoCompetenceSubSites(): string[] {
  return ["sites", "NO-CompetenceLibrary"];
}

export async function getPageDescription(pageName: string): Promise<PageDescription | undefined> {
  try {

    const token = useAuthenticationStore().getTokenLoaded();

    // get description
    const descUrl = getPageDescriptionGraphLink(pageName);
    const response = await axios.get(descUrl, {
      headers: {
        Prefer: "HonorNonIndexedQueriesWarningMayFailRandomly",
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data.value[0].fields as PageDescription;
  } catch (error) {
    console.error("Error fetching page description:", error);
    throw error;
  }
}

export async function getReportLink(reportName: string): Promise<EmbeddedReport | undefined> {
  const url = getPowerBiReportGraphLink(reportName);
  try {
    const token = useAuthenticationStore().getTokenLoaded();
    const response = await axios.get(url, {
      // params: {
      //   filter,
      // },
      headers: {
        Prefer: "HonorNonIndexedQueriesWarningMayFailRandomly",
        Authorization: `Bearer ${token}`,
      },
    });

    const pageTitle = response.data.value[0].fields.Title;
    if (pageTitle) {
      document.title = document.title + ' - ' + pageTitle;
    }
    return response.data;
  } catch (error) {
    console.error("Error calling SharePoint API:", error);
    throw error;
  }
}

/**
 * Represents default sub-site of NO-CompetenceLibrary/PA-OGC-KPI
 */
export function getSPOgcKpiUrl(explicitOrigin = false) {
  return urlJoin(getSPServerUrl(explicitOrigin), getSPOgcKpiRelativeUrl());
}

/**
 * Return url profile picture on SharePoint site.
 * @param {string} email User e-mail.
 * @param {string} size Desire profile picture size: size=S (48×48 px) or size=M (72×72 px) or size=L (300×300 px). Default: size=M.
 */
export function getUserPhotoUrl(email: string, size: "S" | "M" | "L" = "M") {
  return urlJoin(
    getSPServerUrl(),
    `_layouts/15/userphoto.aspx?size=${size}&accountname=${email}`
  );
}

export function getDescriptionItemFormUrl(id?: number): string {
  const url = new URL(
    urlJoin(getSPOgcKpiUrl(true), "Lists/PageDescriptions/EditForm.aspx")
  );
  id && url.searchParams.set("ID", String(id));
  return url.toString();
}

export function getTeamViewLink(geid?: string) {
  const url = new URL(
    urlJoin(getSPCompetenceLibraryUrl(true), "SitePages/TeamView.aspx")
  );
  geid && url.searchParams.set("geid", geid);
  return url.toString();
}

/**
 *
 * @returns {string} Base URL for API requests
 */
export function getServerUrl(): string {
  const baseURL = process.env.VUE_APP_API_BASE_URL;

  if (!baseURL) {
    throw new Error("Base URL not defined in environment variables");
  }
  return baseURL;
}
