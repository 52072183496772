/**
 * Comparator for sorting objects bt specific property
 * @param propFn function to resolve object property
 */
export const sortByProperty =
  <T>(propFn: (object: T) => string | number | Date, asc = true) =>
    (a: T, b: T): number => {
      const propA = propFn(a);
      const propB = propFn(b);

      if (propA > propB) return asc ? 1 : -1;
      if (propA < propB) return asc ? -1 : 1;
      return 0;
    };
