<script setup lang="ts">
import { getUserPhotoUrl } from "@/common/urls";
import TopBarButton from "@/components/topbar/TopBarButton.vue";
import { computed, ref, reactive, watch } from "vue";
import DescriptionModal from "@/components/util/DescriptionModal.vue";
import { useAuthenticationStore } from "@/stores/authentication";
import { useAzureStore } from "@/stores/azure";
import { isDevelopment } from "@/common/env";
import { useModal, ModalsContainer } from "vue-final-modal";

const homePageLink =
  "https://abb.sharepoint.com/sites/NO-CompetenceLibrary/SitePages/TeamView.aspx";
const requestHelpLink =
  "https://abb.sharepoint.com/sites/NO-CompetenceLibrary/Lists/BUOGCSupport/AllItems.aspx?Application=U3t";

  const Authorization = useAuthenticationStore();

const createBlobUrl = (photo: any) => {
  if (photo) {
    const blob = Authorization.getPhoto();
    return URL.createObjectURL(blob);
  }
  return undefined;
};

const descriptionModal = ref<{ open: () => void }>();
const openDescriptionModal = () => {
  descriptionModal.value?.open();
};

const { open, close } = useModal({
    component: DescriptionModal,
  });

</script>

<template>
  <div class="top-bar">
    <TopBarButton icon="fa-solid fa-house" title="Home" :href="homePageLink" class="btn-toolbox--home" />

    <div class="top-bar-divider" />

    <div class="person">

      <img v-if="!Authorization.getPhotoLoader()" :src="createBlobUrl(Authorization.getPhoto)" alt="User Photo"
        class="person-photo person-photo--rounded" />
      <div class="person-name pl-1">
        {{ Authorization.getAccountName() }}
      </div>
    </div>

    <div class="spacer" />
    <TopBarButton icon="fa-solid fa-info" title="Description" @click="open" />
    <TopBarButton icon="fa-solid fa-question-circle" title="Request help" :href="requestHelpLink" target="_blank" />
  </div>

  <ModalsContainer />
</template>

<style lang="scss">
.person {
  display: flex;
  align-items: center;
  margin-left: 5px;
}

.top-bar {
  display: flex;
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  background: #f5f5f5;
}
</style>

<style lang="scss" scoped>
.btn-toolbox--home {
  width: 50px;
}

.person-name {
  font-size: 17px;
}

.bg-gray {
  background-color: gray;
}
</style>
