<script setup lang="ts">
import { SidebarMenu } from "vue-sidebar-menu";
import { storeToRefs } from "pinia";
import { onMounted } from "vue";
import { useU3tNavigationStore } from "@/stores/u3tNavigation";

const useStore = useU3tNavigationStore();
const { navigation } = storeToRefs(useStore);

onMounted(async () => {
  await useStore.getNavigation();
});
</script>

<template>
  <sidebar-menu :menu="navigation" :collapsed="true" width="420px" widthCollapsed="50px" />
</template>

<style lang="scss">
@import "~vue-sidebar-menu/src/scss/vue-sidebar-menu.scss";

.v-sidebar-menu {
  top: 40px;
  z-index: 999;
  height: calc(100% - 40px);
  text-align: left;
}

.v-sidebar-menu {
  .vsm--arrow::after {
    font-family: "Font Awesome 5 Pro";
  }

  .vsm--toggle-btn::after {
    font-family: "Font Awesome 5 Pro";
  }
}

.v-sidebar-menu .vsm--link {
  padding: 10px 5px !important;
}
</style>
