export function getNavigationListName() {
  return "NavigationList";
}

export function getPageDescriptionListName() {
  return "PageDescriptions";
}
export function getPageDescriptionGraphLink(reportName: string) {
  return `https://graph.microsoft.com/v1.0/sites/abb.sharepoint.com,34363488-3376-463d-a1c2-59baf4ccf50c,19c5a9f0-f517-44ff-80fb-5f3abadd8c23/lists/25045fb6-cbbf-471d-9cce-576821320a22/items?$expand=fields&$filter=fields/Title eq '${reportName}'`;
}

export function getPowerBiReportGraphLink(reportName: string) {
  return `https://graph.microsoft.com/v1.0/sites/abb.sharepoint.com,34363488-3376-463d-a1c2-59baf4ccf50c,81bf171d-b6d4-4a0e-b078-a5972911c831/lists/cba55c41-3a17-4dfc-a968-2ac15e54bb86/items?$filter=fields/Url eq '${reportName}'&$expand=fields`;
}

export function getPowerBiListName() {
  return "PowerBi";
}

export function getEmbeddedReportsName() {
  return "EmbeddedReports";
}
