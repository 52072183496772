import { defineStore } from "pinia";
import { fetchGroupMember } from "@/services/user";

interface UserState {
  access: "manager" | "user";
}

const adminGroupName = "PA-OGC-ET-KPI Owners";

export const useUserStore = defineStore({
  id: "user",
  state: () =>
    ({
      access: "manager",
    } as UserState),
  getters: {
    isManager: (state) => state.access === "manager",
  },
  actions: {
    async hasUserAccessToGroup() {
      const items = await fetchGroupMember(adminGroupName);
      if (items[0]) this.access = "manager";
    },
  },
});
