import type { NavigationItem } from "@/types/NavigationListItem";
import axios from "axios";
import { useAuthenticationStore } from "@/stores/authentication";

const navigationListName = "NavigationList";
const select = [
  "Id",
  "CategoryLookupId",
  "Title",
  "PermissionId",
  "LBUNorway",
  "Order0",
  "Url",
  "Icon",
];

export async function fetchNavigation(): Promise<NavigationItem[]> {
  const token = useAuthenticationStore().getTokenLoaded();
  const navigationUrl = "https://graph.microsoft.com/v1.0/sites/abb.sharepoint.com,34363488-3376-463d-a1c2-59baf4ccf50c/lists/ff063682-8990-4416-aa37-1e25008565b0/items?$expand=fields";
  const response = await axios.get(navigationUrl, {
    headers: {
      Prefer: "HonorNonIndexedQueriesWarningMayFailRandomly",
      Authorization: `Bearer ${token}`,
    },
  });
  const items = response.data.value.map((item: any) => item.fields) as NavigationItem[];
  if (items) return items;
  throw new Error("Navigation list isn't found");
}

// export async function fetchNavigation(): Promise<NavigationItem[]> {
//   const items = await sp.web.lists
//     .getByTitle(navigationListName)
//     .items.select(...select)<NavigationItem[]>();
//   if (items[0]) return items;
//   throw new Error("Navigation list isn't found");
// }