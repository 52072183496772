<template>
    <div>
        <div class="tile">
            <iframe class="powerbiReport" width="100%" :src="props.link" frameborder="0" allowfullscreen="true"
                style="height: 85vh;"></iframe>
        </div>
    </div>
</template>

<script setup>
// Your script code here
import { defineProps } from 'vue';

const props = defineProps({
    link: String
});

</script>

<style scoped>
/* Your styles here */
</style>