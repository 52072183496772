import { sp } from "./pnpjs";
import { Caching } from "@pnp/queryable";
import { loggedUser } from "@/common/loggedPerson";

export function fetchGroupMember(group: string) {
  return sp.web.siteGroups
    .getByName(group)
    .users.filter(`Id eq ${loggedUser.id}`)
    .using(Caching({ store: "session" }))();
}
