import { defineStore } from "pinia";
import { sortByProperty } from "@/common/sorts";
import { NavigationItem } from "@/types/NavigationListItem";
import { SidebarItem } from "@/types/SidebarIListItem";
import { fetchNavigation } from "@/services/u3tNavigation";
import { isDevelopment } from "@/common/env";

interface U3tNavigationState {
  menu: NavigationItem[];
}

export const useU3tNavigationStore = defineStore({
  id: "u3tNavigation",
  state: () =>
  ({
    menu: [],
  } as U3tNavigationState),
  getters: {
    navigation: (state) => {
      const items = mappingNavigationItem(state.menu);
      return getMenuTree(items);
    },
  },
  actions: {
    async getNavigation() {
      this.menu = await fetchNavigation();
    },
  },
});

function appendChildToParent(parent: SidebarItem, child: SidebarItem): void {
  parent.child ? parent.child.push(child) : (parent.child = [child]);
}

function getItemsHashedById(items: SidebarItem[]): Map<number, SidebarItem> {
  const map = new Map<number, SidebarItem>();
  for (const item of items) {
    map.set(item.id, item);
  }
  return map;
}

function mappingNavigationItem(list: NavigationItem[]): SidebarItem[] {
  return list.map((item) => {
    const newItem: SidebarItem = {
      id: item.id,
      parentId: item.CategoryLookupLookupId,
      title: item.Title,
      order: item.Order0,
      icon: item.Icon,
      external: true,
    };
    if (item.Url) {
      newItem.href = item.Url;
    }
    return newItem;
  });
}

function getMenuTree(items: SidebarItem[]): SidebarItem[] {
  const map = getItemsHashedById(items);

  const topLevelItems: SidebarItem[] = [];
  for (const item of items) {
    if (!item.parentId) {
      topLevelItems.push(item);
      continue;
    }

    const parent = map.get(item.parentId);
    if (parent) {
      appendChildToParent(parent, item);
    }
  }

  topLevelItems.sort(sortByProperty((x) => x.order));
  return topLevelItems;
}


