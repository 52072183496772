import { spfi, SPBrowser } from "@pnp/sp";
import "@pnp/sp/webs";
import "@pnp/sp/lists";
import "@pnp/sp/items";
import "@pnp/sp/site-groups";

import { getSPCompetenceLibraryUrl, getSPOgcKpiUrl } from "@/common/urls";

export const sp = spfi().using(
  SPBrowser({ baseUrl: getSPCompetenceLibraryUrl() })
);
export const spOgcKpi = spfi().using(SPBrowser({ baseUrl: getSPOgcKpiUrl() }));
