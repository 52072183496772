/* eslint-disable */
import { isDevelopment } from "./env";

if (isDevelopment) {
  window._spPageContextInfo = {
    userDisplayName: "Roman Cerny",
    userEmail: "roman.cerny@cz.abb.com",
    //userEmail: "miroslav.urbanek@cz.abb.com",
    userId: 12357,
    portalUrl: "https://abb.sharepoint.com/",
    siteAbsoluteUrl: "http://localhost:8080/sites/NO-CompetenceLibrary",
    siteServerRelativeUrl: "/sites/NO-CompetenceLibrary",
    webAbsoluteUrl: "http://localhost:8080/sites/NO-CompetenceLibrary/PA-OGC-KPI",
    webServerRelativeUrl: "/sites/NO-CompetenceLibrary/PA-OGC-KPI",
    webTitle: "OGC-KPI",
  } as _spPageContextInfo;
}
