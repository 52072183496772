import "@/common/mockPageContextInfo";
import { createApp } from 'vue';
import App from './App.vue';
import { createPinia } from "pinia";
import { createVfm } from "vue-final-modal";
import "vue-final-modal/style.css";
import { createRouter, createWebHistory } from "vue-router";
/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";
/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
    faCoffee,
    faHome,
    faPercentage,
    faInfoCircle,
    faPencilAlt,
    faPlus,
    faEdit,
    faInfo,
    faQuestionCircle,
    faToriiGate,
    faCircleExclamation,
    faChevronRight,
    faRotateLeft,
    faXmark,
    faGear,
    faThumbsUp,
    faClipboardList,
    faLineChart,
} from "@fortawesome/free-solid-svg-icons";
/* add icons to the library */
library.add(
    faCoffee,
    faHome,
    faPercentage,
    faInfoCircle,
    faPencilAlt,
    faPlus,
    faEdit,
    faInfo,
    faQuestionCircle,
    faToriiGate,
    faCircleExclamation,
    faChevronRight,
    faRotateLeft,
    faXmark,
    faGear,
    faThumbsUp,
    faClipboardList,
    faLineChart,
);

import '@fortawesome/fontawesome-free/css/all.min.css';

const routes = [
    { path: '/', component: App },
];
const router = createRouter({
    history: createWebHistory(),
    routes: routes,
});


const app = createApp(App);
const vfm = createVfm();

app.use(createPinia())
    .use(vfm)
    .use(router)
    .component("font-awesome-icon", FontAwesomeIcon)
    .mount('#app');
