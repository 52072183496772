<template>
  <div class="breadCrumbsNavigation">
    <div class="breadCrumbItem">
      <a
        href="https://abb.sharepoint.com/sites/NO-CompetenceLibrary/SitePages/TeamView.aspx"
      >
      <slot></slot>
      <font-awesome-icon icon="fa-solid fa-house" />
        U3t
      </a>
    </div>
    <div class="breadCrumbItem">
      <span class="breadcrumb-delimiter">·</span>
            
      <font-awesome-icon icon="fa-solid fa-line-chart" />
        {{ props.reportName }}
    </div>
    <!-- <div
      class="breadCrumbItem"
      v-bind:class="{ activeLink: index == items.length - 1 }"
      v-for="(item, index) in items"
      :key="index"
    >
      <span class="breadcrumb-delimiter">·</span>
      <a :href="item.link">
        <i :class="item.icon"></i>
        {{item.label}}
      </a>
    </div> -->
  </div>
</template>
<script setup lang="ts">
import { defineProps } from 'vue';

const props = defineProps({
  reportName: String,
});



</script>

<style scoped>
.breadCrumbsNavigation {
  line-height: 36px;
  padding-left: 7px;
  margin-top: 6px;
  color: #6c757d;
}

.breadCrumbItem {
  float: left;
}

.breadCrumbItem a {
  color: #888;
}

.breadCrumbItem a:hover,
.activeLink a {
  color: #212529;
  text-decoration: none;
}

.breadcrumb-delimiter {
  margin-right: 8px;
  margin-left: 8px;
}

.breadcrumb-delimiter:last-child {
  display: none;
}
</style>
