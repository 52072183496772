<template>
  <TopBar />
  <SideBar v-if="state.status == 1" />
  <div class="toolbar">
    <ToolBar :reportName="state.name"/>

  </div>
  <div>
    <ReportPage :link="state.link" :name="state.name" />
  </div>
</template>

<script setup lang="ts">
import TopBar from "@/components/topbar/TopBar.vue";
import SideBar from "@/components/SideBar.vue";
import ToolBar from "@/components/toolbar/ToolBar.vue";
import ReportPage from "@/components/report/ReportPage.vue";
import { useAuthenticationStore, LogStatus } from "@/stores/authentication";
import { reactive, onMounted } from "vue";
import { getReportLink } from "./common/urls";

const state = reactive({
  status: 0,
  url: "",
  name: "",
  description: "",
  link: "",
});

onMounted(async () => {
  const Authorization = useAuthenticationStore();
  loggedCheck(Authorization.logStatus);
  state.status = 0;
  await Authorization.login();
  if (Authorization.getLoggedStatus() == LogStatus.Logged) {
    state.status = 1;
    loggedCheck(Authorization.logStatus);
  }
  else {
    state.status = -1;
  }
  console.log(state.status);
  // get parameters from url
  const urlParams = new URLSearchParams(window.location.search);
  state.url = urlParams.get("url") ?? "";
  const response = await getReportLink(state.url);
  state.link = response.value[0].fields.ReportLink.Url;
  state.name = response.value[0].fields.Title;
});

function loggedCheck(value: LogStatus) {
  if (value == LogStatus.Logged) {
    const destination = sessionStorage.getItem("log_to") ?? '/';
    sessionStorage.removeItem("log_to");
  }
}

</script>

<style lang="scss">
@import "assets/scss/personPhoto.scss";
@import "assets/scss/common.scss";

.toolbar {
  display: flex;
  align-items: center;
  width: 100%;
}

body {
  height: 100%;
  margin: 0;
  font-family: ABBVoice, Verdana, Arial, sans-serif;
  background-color: #e6e6e6 !important;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin: 35px 12px 0 55px;
}

.row.short-gutters {
  margin-right: -7px;
  margin-left: -7px;
}

.short-gutters>.col,
.short-gutters>[class*="col-"] {
  padding-right: 7px;
  padding-left: 7px;
}

.vertical-gutters>.col,
.vertical-gutters>[class*="col-"] {
  padding-top: 7px;
  padding-bottom: 7px;
}

.vertical-gutters-top>.col,
.vertical-gutters-top>[class*="col-"] {
  padding-bottom: 7px;
}
</style>
