/* eslint-disable */
import { isDevelopment } from "@/common/env";
import { PublicClientApplication } from "@azure/msal-browser";
import { defineStore } from 'pinia';
import { reactive } from "vue";

export enum LogStatus {
    None,
    NotAuthorized,
    NoAccess,
    Logged,
}

const clientId = process.env.VUE_APP_U3T_ADAL_CLIENT_ID as string;

const msalConfig = {
    auth: {
        clientId: clientId,
        authority:
            "https://login.microsoftonline.com/372ee9e0-9ce0-4033-a64a-c07073a91ecd",
        redirectUri: '/',
    },
    cache: {
        cacheLocation: "sessionStorage",
    },
    system: {
        tokenRenewalOffsetSeconds: 600,
        iframeHashTimeout: 30000, // iframe is used to refresh the refresh_token as it has a 24h lifetime
    },
};


export const useAuthenticationStore = defineStore('authentication', () => {
    const state = reactive({
        logStatus: LogStatus.None,
        name: "Uknown user",
        token: "",
        id: "",
        userPhoto: {
            blob: null as Blob | null,
            loader: true,
        },
    });
    // let logStatus = LogStatus.None;
    // let name = "Uknown user";
    // let token = "";
    // let id = "";
    const msalInstance = new PublicClientApplication(msalConfig);

    function logout() {
        // Perform logout logic here
        state.logStatus = LogStatus.None;
        state.name = "Uknown user";
    }
    async function login() {
        await getToken();
        loadPhoto();
    }
    async function getToken() {
        await msalInstance.initialize();
        await msalInstance.handleRedirectPromise();
        const accounts = msalInstance.getAllAccounts();
        const silentRequest = {
            scopes: ['.default'], // add the desired scope here
            account: accounts[0],
        };
        try {
            await msalInstance
                .acquireTokenSilent(silentRequest)
                .then((response) => {
                    state.token = response.accessToken;
                    state.logStatus = LogStatus.Logged;
                    state.name = response.account?.name ?? "";
                    state.id = response.account?.localAccountId ?? "";
                });
        } catch (e) {
            try {
                await msalInstance.loginRedirect();
            } catch (e) {
                console.error(e);
                state.logStatus = LogStatus.NotAuthorized;
            }
        }
    }

    async function loadPhoto() {
        try {
            state.userPhoto.loader = true;
            const response = await fetch('https://graph.microsoft.com/v1.0/me/photo/$value', {
                headers: {
                    Authorization: `Bearer ${useAuthenticationStore().getTokenLoaded()}`,
                },
            });
            if (response.ok) {
                const blob = await response.blob();
                state.userPhoto.blob = blob;
                state.userPhoto.loader = false;
            } else {
                console.error('Failed to fetch photo:', response.status, response.statusText);
            }
        } catch (error) {
            console.error('Error fetching photo:', error);
        }
    }

    function getPhoto(): any {
        return state.userPhoto.blob;
    }
    function getPhotoLoader(): boolean {
        return state.userPhoto.loader;
    }
    function getLoggedStatus(): LogStatus {
        return state.logStatus;
    }
    function getAccountName(): string {
        return state.name;
    }
    function getId(): string {
        return state.id;
    }
    function getTokenLoaded(): string {
        return state.token;
    }
    function isLogged(): boolean {
        return state.logStatus == LogStatus.Logged;
    }
    function getInstance(): PublicClientApplication {
        return msalInstance;
    }


    return {
        login,
        logout,
        getToken,
        getLoggedStatus,
        getAccountName,
        getId,
        getTokenLoaded,
        isLogged,
        getInstance,
        loadPhoto,
        getPhoto,
        getPhotoLoader,
    };
});