/* eslint-disable */

export const loggedUser = {
  get id(): number {
    return _spPageContextInfo.userId;
  },
  get email(): string {
    return _spPageContextInfo.userEmail;
  },
  get name(): string {
    return _spPageContextInfo.userDisplayName;
  },
};
